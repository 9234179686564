@font-face {
  font-family: 'Roseritta';
  src: local('Roseritta'), url(./fonts/Roseritta.otf) format('opentype');
}

@font-face {
  font-family: 'Mesmerize';
  src: local('Mesmerize'), url(./fonts/mesmerize-bd.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roseritta', 'Mesmerize', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
